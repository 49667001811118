/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

export const REACT_BASE_PATH = process.env.REACT_APP_BASE_URL || '';
export const BASE_ORIGIN = `${window.location.origin}${REACT_BASE_PATH}`;

export const RouteAlias = {
  home: '/',
  login: '/users/login',
  signUp: '/users/register',
  inactive: '/users/login?status=inactive',
  accountRecovery: '/users/account-recovery',
  changeEmail: '/users/change-email',
  passwordReset: '/users/password-reset',
  accountActivation: '/users/account-activation',
  activationSuccess: '/users/account-activation/success',
  activationFailed: '/users/account-activation/failed',
  suspended: '/users/account-suspended',
  confirmNewEmail: '/users/confirm-new-email',
  confirmEmail: '/users/confirm-email',
  authLanding: '/users/auth-landing',
};
